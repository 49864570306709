import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, Navigate, Outlet, RouterProvider, useNavigate} from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';
import Monitors from './components/monitors';
import "@cloudscape-design/global-styles/index.css";
import CreateMonitor from './components/create_monitor';
import {
    Alert,
    AppLayout,
    Button,
    ContentLayout,
    Header,
    SideNavigation,
    SpaceBetween
} from '@cloudscape-design/components';
import axios from 'axios';
import {I18nProvider} from "@cloudscape-design/components/i18n";
import messages from '@cloudscape-design/components/i18n/messages/all.en';

function FairwayFinderApp() {
    const [authorized, setAuthorized] = useState(undefined)

    const navigate = useNavigate();

    function followLink(e) {
        e.preventDefault()
        navigate(e.detail.href)
    }

    useEffect(() => {
        async function getIdentity() {
            try {
                await axios.get("/api/userInfo");
                setAuthorized(true)
            } catch (error) {
                console.log(error);
                if ([401, 403].indexOf(error.response?.status)) {
                    setAuthorized(false)
                }
            }
        }

        let _ = getIdentity().await;
    }, [])
    if (authorized === true) {
        return (<AppLayout
            navigationHide={false}
            navigation={
                <SideNavigation
                    header={{href: "/", text: "Fairway Finder"}}
                    onFollow={followLink}
                    items={[
                        {text: 'Monitors', href: '/monitors', type: 'link'},
                    ]}
                />
            }
            toolsHide={true}
            content={
                <Outlet context={[authorized, setAuthorized]}/>
            }
        />)
    } else if (authorized === false) {
        return <ContentLayout
            defaultPadding
            header={
                <SpaceBetween size="m">
                    <Alert header='Unauthorized' type='error'>Continue to <Button
                        href="https://auth.fairwayfinder-dev.bryangardner.dev/login?client_id=7gptbimo53sj6n68ule8fhm4cj&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https%3A%2F%2Ffairwayfinder-dev.bryangardner.dev%2Fapi%2Fauthenticate"
                        variant='inline-link'>Login</Button>.</Alert>
                </SpaceBetween>
            }/>
    } else {
        return <ContentLayout
            defaultPadding
            header={
                <Header
                    variant="h1"
                    description="Loading..."
                >
                    Fairway Finder
                </Header>
            }
        />
    }
}


const router = createBrowserRouter([
    {
        children: [
            {
                path: "/",
                element: <Navigate to="/monitors"/>
            },
            {
                path: "monitors",
                element: <Monitors/>,
            },
            {
                path: "monitors/create",
                element: <CreateMonitor/>
            }
        ],
        element: <FairwayFinderApp/>
    }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <I18nProvider locale={"en"} messages={[messages]}>
            <RouterProvider router={router}/>
        </I18nProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
